import { render, staticRenderFns } from "./generateCourse.vue?vue&type=template&id=476996cb&scoped=true&"
import script from "./generateCourse.vue?vue&type=script&lang=js&"
export * from "./generateCourse.vue?vue&type=script&lang=js&"
import style0 from "./generateCourse.vue?vue&type=style&index=0&id=476996cb&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476996cb",
  null
  
)

export default component.exports